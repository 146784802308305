body,
html,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
header,
menu,
section,
p,
input,
td,
th,
ins {
	padding: 0;
	margin: 0;
}

a {
	text-decoration: none;
	color: #333;
	/* &:hover {
    color: #333;
    text-decoration: underline;
  } */
}

img {
	vertical-align: top;
}

ul,
li {
	list-style: none;
}

button {
	outline: none;
	border: none;
}
