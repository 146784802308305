body,
textarea,
select,
input,
button {
  font-size: 14px;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f5f5f5;
}

.nav-item{
  display: block;
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.nav-item:hover{
  color: #486da0 !important;
}
